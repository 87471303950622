import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SnackbarProvider } from 'notistack';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UserProvider } from './UserContext';
import AppContent from './AppContent';
import Maintenance from './maintenance';


function App() {
  const paypalOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
    components: "buttons",
    locale: "en_US"
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <UserProvider>
          <PayPalScriptProvider options={paypalOptions}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              autoHideDuration={5000}
              style={{ zIndex: 999999 }} 
            >
              <div className="App">
                <AppContent />
              </div>
            </SnackbarProvider>
          </PayPalScriptProvider>
        </UserProvider>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;