import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useUser } from './UserContext';

const DefaultLayout = lazy(() => import('./components/layout/DefaultLayout'));
const Home = lazy(() => import('./components/Hero/Home'));
const TermsAndConditions = lazy(() => import('./components/Resources/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./components/Resources/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('./components/Resources/RefundPolicy'));

const Loading = () => <div>Loading...</div>;

const ProtectedRoute = React.memo(({ children }) => {
  const { user, loading } = useUser();
  if (loading) return <Loading />;
  return user ? children : <Navigate to="/" replace />;
});

const AppContent = React.memo(() => {
  return (
    <Routes>
      <Route path="/" element={
        <DefaultLayout>
          <Suspense fallback={<Loading />}>
            <Home />
          </Suspense>
        </DefaultLayout>
      } />
      <Route path="/generate" element={
        <ProtectedRoute>
          <DefaultLayout>
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          </DefaultLayout>
        </ProtectedRoute>
      } />
      <Route path="/:section" element={
        <DefaultLayout>
          <Suspense fallback={<Loading />}>
            <Home />
          </Suspense>
        </DefaultLayout>
      } />
      <Route path="/terms" element={
        <DefaultLayout>
          <Suspense fallback={<Loading />}>
            <TermsAndConditions />
          </Suspense>
        </DefaultLayout>
      } />
      <Route path="/privacy" element={
        <DefaultLayout>
          <Suspense fallback={<Loading />}>
            <PrivacyPolicy />
          </Suspense>
        </DefaultLayout>
      } />
      <Route path="/refund" element={
        <DefaultLayout>
          <Suspense fallback={<Loading />}>
            <RefundPolicy />
          </Suspense>
        </DefaultLayout>
      } />
    </Routes>
  );
});

export default AppContent;